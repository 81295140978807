<template>
	<div class="members main-cnt">
		<div class="left-wp">
			<div class="input-wp">
				<el-input v-model.trim="filterText" clearable @keypress="getTreeData()" placeholder="输入组织名称搜索"
					:suffix-icon="Search" />
			</div>
			<div class="tree-wp" v-loading="treeLoading" element-loading-text="数据加载中">
				<el-scrollbar style="height: 100%">
					<el-tree ref="tree" :data="treeData" :props="{
							children: 'children',
							label: 'label'
						}" show-checkbox check-strictly accordion node-key="tree_monitor_id" :default-expanded-keys="defaultExpanded"
						:default-checked-keys="defaultChecked" :expand-on-click-node="false" @node-expand="nodeExpand"
						@node-collapse="nodeCollapse">
						<template #default="{ node, data }">
							<span class="custom-tree-node">
								<span @click="nodeClick(data)">
									{{ node.label }}

									<span
										v-if="!data.me_id">{{ '(' + data.device_online_count + '/' + data.device_all_count + ')' }}</span>
									<span v-if="data.me_id"
										class="text-gray">{{ data.me_hik_status == 3 ? '(离线)' : '' }}</span>
									<span v-if="data.me_id"
										class="text-gray">{{ data.me_hik_status == 1 ? '(待接入)' : '' }}</span>
									<span v-if="data.me_id"
										class="text-blue">{{ data.me_hik_status == 2 ? '(在线)' : '' }}</span>
								</span>

								<!-- <span v-if="data.check">
									<a style="margin-left: 8px">
										<el-icon color="#0072F6"><Select /></el-icon>
									</a>
								</span> -->

							</span>
						</template>
					</el-tree>
				</el-scrollbar>
			</div>
		</div>
		<div class="right-wp">
			<div class="content">
				<div class="mcontent">
					<div class="video-switch">
						<div class="video-switch-tab">
							<div class="video-switch-tab-line" :style="switchLineStyle"></div>
							<div class="video-switch-tab-btn" @click="switchTo(0)"
								:class="{ 'tab-active': switchVideo === 0 ? true : false }">实时预览</div>
							<div class="video-switch-tab-btn" @click="switchTo(1)"
								:class="{ 'tab-active': switchVideo === 1 ? true : false }">录像回放</div>
						</div>
					</div>
					<div id="playWnd" style="width: 1200px;height:600px;"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import {
		ref,
		reactive,
		onMounted,
		computed,
		watch,
		nextTick,
		onBeforeUnmount
	} from 'vue';
	import {
		Search
	} from '@element-plus/icons-vue';
	import {
		ElMessage
	} from 'element-plus';
	import cookie from '@/utils/cookie';

	import {
		BasicApi,
		Synthesize
	} from '@/plugins/api.js';
	import {
		useStore
	} from 'vuex';

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);

	const switchLineStyle = computed(() => (switchVideo.value === 0 ? {
		transform: 'translateX(0px)'
	} : {
		transform: 'translateX(88px)'
	}));
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		data => {
			if (data.length) {
				authData.value = data;
			}
		}, {
			deep: true,
			immediate: true
		}
	);


	//卸载页面时 关闭窗口
	onBeforeUnmount(() => {
		setTimeout(() => {
			wCancelPreviewVideo()
		}, 1000)
	})

	/**************监控相关***************/

	const switchVideo = ref(0); // 0实时预览 1录像回放
	var oWebControl = ref(null);
	const self = reactive({
		appKey: '',
		ezvizToken: '',
		platformId: '',
		layout: 0,
		deviceSerial: '',
		channelNo: '',
		startTime: '',
		endTime: '',
		deviceClass: '0'
	});
	/**
	 * 生命周期
	 */
	onMounted(async () => {
		getTreeData();
		await BasicApi.getHikEzvizAccount({}).then(res => {
			if (res.Code == 200) {
				self.appKey = res.Data.appKey;
				self.ezvizToken = res.Data.token;

			}
		});
		// 初始化视频控件
		oWebControl.value = await WebControlInit('playWnd', cbConnectSuccess, cbConnectError, cbConnectClose);
		setTimeout(() => {
			initVideo();
		}, 3000);
	});

	/**
	 * video 初始化及配置
	 * @param {*video挂载的dom id} id
	 * @param {*连接成功的回调} cbConnectSuccess
	 * @param {*连接报错的回调} cbConnectError
	 * @param {*连接关闭的回调} cbConnectClose
	 */
	function WebControlInit(id, cbConnectSuccess, cbConnectError, cbConnectClose) {
		return new WebControl({
			szPluginContainer: id,
			iServicePortStart: 14510, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
			iServicePortEnd: 14519, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
			cbConnectSuccess: cbConnectSuccess,
			cbConnectError: cbConnectError,
			cbConnectClose: cbConnectClose
		});
	}

	// 设置窗口控制回调
	async function cbConnectSuccess() {
		await oWebControl.value.JS_SetWindowControlCallback({
			cbIntegrationCallBack: cbIntegrationCallBack
		});
		//创建视频窗口
		await oWebControl.value
			.JS_StartService('window', {
				dllPath: './chain/cloudTransform.dll'
			})
			.then(function() {});
		await oWebControl.value.JS_CreateWnd('playWnd', 900, 500).then(function() {
			// console.log('JS_CreateWnd success');
		});
	}

	function cbConnectError() {
		oWebControl.value = null;
		console.error('确认本地进程是否已安装并开启成功！');
	}

	function cbConnectClose() {
		oWebControl.value = null;
	}
	const windNum = ref([]) //视频个数
	const windActiveIndex = ref(0)
	const windCloseIndex = ref(0)
	// 监听视频控件的事件
	function cbIntegrationCallBack(obj) {
		//关闭视频清除选中状态
		if (obj.responseMsg.eventName == "FireWndCloseData") {
			windCloseIndex.value = obj.responseMsg.arguments.wndId
			treeData.value.forEach(tree => {
				tree.children.forEach(child => {
					child.children.forEach(c => {
						if (c.me_id == windNum.value[windCloseIndex.value].node.me_id) {
							c.check = false
						}
					})
				})
			})
			windNum.value[windCloseIndex.value].node = ''
		}
		//选中视频播放
		if (obj.responseMsg.eventName == "FireWndInfo") {
			// 如果视频控件需要请求服务端数据，这里可以处理透传逻辑
			windActiveIndex.value = obj.responseMsg.arguments.wndId
		}
		//视频个数
		if (obj.responseMsg.eventName == "FireWndCount") {
			for (var i = 0; i < obj.responseMsg.arguments.wndNum; i++) {
				windNum.value.push({
					index: i
				})
			}
		}
	}
	/**
	 * 设置事件回调信息
	 */
	const callbackMessage = ref('');

	function showCBInfo(message) {
		callbackMessage.value = callbackMessage.value + JSON.stringify(message) + '\n\n';
	}

	// 初始化视频
	function initVideo() {
		if (!self.appKey || !self.ezvizToken) {
			ElMessage.error('请输入appkey和token');
			return;
		}
		// 初始化入参
		let argumentsPram = {
			layout: self.layout,
			userName: '',
			waterMark: '',
			intelligenceEnable: 1,
			isRecord: 1,
			isSetPos: 1,
			motionDetectEnable: 0,
			playBackAlarmOverlyingEnable: 0,
			response: {
				code: 0,
				message: null,
				data: {
					appKey: self.appKey,
					ezvizToken: self.ezvizToken,
					videoLevel: 0,
					showMainTool: 1,
					showSubTool: 1,
					waterMark: '1',
					userName:cookie.getCookie('user_name'), //水印
					platformId: '1'
				}
			}
		};
		argumentsPram.response.data.userName =sessionStorage.getItem('user_name');
		// 调用视频控件初始化方法
		oWebControl.value
			.JS_RequestInterface({
				funcName: 'Init',
				arguments: encodeURI(JSON.stringify(argumentsPram))
			})
			.then(function(oData) {
				showCBInfo(oData.responseMsg);
				// ElMessage.success('视频初始化成功');
				// playVideo();
			});
	}
	const wCancelPreviewVideo = () => {
		//隐藏窗口
		oWebControl.value.JS_HideWnd();
		oWebControl.value.JS_DestroyWnd();
	};
	const switchTo = num => {
		switchVideo.value = num;
		if (oWebControl.value) {
			oWebControl.value
				.JS_RequestInterface({
					funcName: 'ChangeModel',
					arguments: encodeURI(
						JSON.stringify({
							model: num
						})
					)
				})
				.then(function() {
					playVideo();
				});
		}
	};
	// 播放视频
	const playVideo = () => {
		if (!self.deviceSerial || !self.channelNo) {
			ElMessage.error('请输入设备序列号和通道号');
			return;
		}
		// 预览入参
		let argumentsPram = {
			response: {
				code: 0,
				message: null,
				data: {
					deviceSerial: self.deviceSerial,
					channelCode: self.channelNo,
					channelNo: self.channelNo,
					codeIsEncrypt: 0,
					validateCode: '',
					deviceClass: self.deviceClass,
					deviceType: '10242',
					//deviceType:"10240",
					channelId: '2222222222',
					channelName: 'channelNameTest',
					storeName: 'storeName',
					storeId: 'storeid',
					platformId: '1'
					//startTime:'2021-03-21 01:00:00',
					//endTime:'2021-03-21 01:02:00',
					//Limited_start:limitStart,
					//Limited_end:limitEnd,
				}
			}
		};

		// 回放时对应的两个字段
		if (switchVideo.value === 1) {
			if (self.startTime) argumentsPram.response.data.startTime = self.startTime;
			if (self.endTime) argumentsPram.response.data.endTime = self.endTime;
		}
		// 调用视频控件预览方法
		oWebControl.value
			.JS_RequestInterface({
				funcName: switchVideo.value === 0 ? 'StartPreview' : 'StartPlayback',
				arguments: encodeURI(JSON.stringify(argumentsPram))
			})
			.then(function(oData) {
				showCBInfo(oData.responseMsg);
				ElMessage.success('预览/回放成功！');
			});
	};
	/**************监控相关***************/

	/** 搜索框绑定值 */
	const filterText = ref('');
	/** 树形结构组件对象 */
	const tree = ref(null);
	/** 树结构加载标识 */
	const treeLoading = ref(false);
	/** 当前选择的组织 */
	const currentOrg = ref(null);
	/** 树形结构 数据 */
	const treeData = ref([]);
	/** 默认展开节点 */
	const defaultExpanded = ref([]);
	/** 默认选择节点 */
	const defaultChecked = ref([]);
	/** 节点点击回调 */
	const extraParame = ref({
		type: 2,
		mg_id: '',
		s_id: ''
	});
	const nodeClick = node => {
		extraParame.value.mg_id = node?.mg_id;
		extraParame.value.s_id = node?.s_id;

		currentOrg.value = node;
		tree.value.setCheckedNodes([node]);
		titleName.value = '';
		nextTick(() => {
			if (node.me_id) {
				if (node.me_hik_status == 2) {
					previewMonitor(node)
					titleName.value = node.label
					windNum.value[windActiveIndex.value].node = node
				} else {
					ElMessage.error('设备离线！');
				}
			}
		});
	};

	/** 节点展开事件 */
	const nodeExpand = data => {
		defaultExpanded.value.push(data.org_id);
	};
	/** 节点关闭事件 */
	const nodeCollapse = data => {
		let index = defaultExpanded.value.findIndex(item => item == data.org_id);
		defaultExpanded.value.splice(index, 1);
	};
	/** 获取组织树形结构数据 */
	const getTreeData = () => {
		treeLoading.value = true;
		Synthesize.getIntegrateMonitor({
			keywords: filterText.value,
			type: 1
		}).then(res => {
			if (res.Code === 200) {
				treeData.value = res.Data ? res.Data : [];
				treeData.value.forEach(resp => {
					resp.label = resp.s_name
					resp.children = resp.group_list;
					resp.children.forEach(group => {
						group.label = group.mg_name;
						group.children = group.device_ist
						group.children.forEach(m => {
							m.label = m.me_name
						})
					});
				});
			} else {
				let msg = res.Message ? res.Message : '获取树形组织结构失败！';
				ElMessage.error(msg);
			}
			treeLoading.value = false;
		});
	};

	/** 当前组织及父级名称 */
	const titleName = ref('');


	/** 预览视频 */
	const previewMonitor = async row => {
		self.deviceSerial = row.me_hik_no;
		self.channelNo = 1;

		playVideo()
	};
</script>

<style lang="scss">
	.el-cascader {
		width: 100%;

	}

	.text-blue {
		color: #00aaff;
	}

	.video-switch {
		height: 48px;
		width: 900px;
		background: #333333;
		display: flex;
		align-items: center;
		margin-top: 20px;
	}

	.video-switch-tab {
		height: 48px;
		margin: 0 auto;
		transition: transform 0.3s;
		position: relative;
	}

	.video-switch-tab-line {
		position: absolute;
		width: 88px;
		top: 0;
		left: 0;
		height: 3px;
		background-color: #e72528;
		z-index: 1;
		transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.video-switch-tab-btn {
		font-size: 12px;
		font-weight: 801;
		float: left;
		font-family: 'MicrosoftYaHei-Bold';
		line-height: 48px;
		color: var(--text-white-color);
		opacity: 0.6;
		width: 88px;
		box-sizing: content-box;
		text-align: center;
	}

	.members {
		font-family: 'Source Han Sans CN';
		display: flex;
		background-color: var(--theme-bg-color);

		.custom-tree-node {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			padding-right: 8px;
		}

		.left-wp {
			flex: 1;
			border-radius: 10px;
			overflow: hidden;
			background-color: var(--bg-content-color);
			padding: 20px 0;
			box-sizing: border-box;

			.input-wp {
				padding: 0 15px;
			}

			>.tree-wp {
				margin-top: 15px;
				height: calc(100% - 58px);

				.el-tree {
					padding: 0 15px;

					.el-tree-node {
						.el-tree-node__content {
							height: 40px;

							>label.el-checkbox {
								display: none;
							}

							.el-tree-node__expand-icon {
								font-size: 16px;
							}
						}

						.el-tree-node__content:hover {
							background-color: var(--search-bg-color);
						}
					}

					.el-tree-node:focus {
						>.el-tree-node__content {
							background-color: var(--search-bg-color);
						}
					}

					.el-tree-node.is-checked {
						>.el-tree-node__content {
							background-color: var(--search-bg-color);

							>.el-tree-node__label {
								color: var(--theme-color);
							}

							>.el-tree-node__expand-icon {
								color: var(--theme-color);
							}

							>.el-tree-node__expand-icon.is-leaf {
								color: transparent;
							}
						}
					}
				}
			}
		}

		.right-wp {
			flex: 3;
			margin-left: 16px;
			border-radius: 10px;
			background-color: var(--bg-content-color);

			.content {
				padding: 20px;
			}
		}

		.organization {
			.el-dialog {
				min-width: 640px;

				.el-dialog__body {
					padding: 0 15px 50px;

					.org-form {
						display: flex;
						flex-wrap: wrap;

						.el-form-item {
							width: 50%;
							padding: 20px 15px 0;
							margin-bottom: 0;

							.el-form-item__content {
								.el-select {
									width: 100%;

									.el-input {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}

		.add-dialog {
			.el-dialog {
				min-width: 840px;

				.el-dialog__body {
					padding: 0 15px 40px;

					.add-form {
						display: flex;
						flex-wrap: wrap;

						.el-form-item {
							width: 50%;
							padding: 20px 15px 0;
							margin-bottom: 0;

							.el-form-item__content {
								.el-select {
									width: 100%;

									.el-input {
										width: 100%;
									}
								}
							}
						}

						.el-form-item.block {
							width: 100%;
						}

						.el-divider {
							margin: 30px 0 0 15px;
							width: calc(100% - 30px);
						}
					}
				}
			}
		}

		.auth-dialog {
			.el-dialog {
				min-width: 840px;

				.el-dialog__body {
					padding: 0;

					.mcontent {
						padding: 25px 25px 50px;
						min-height: 600px;

						.name {
							font-size: 15px;
							color: var(--top-text-color);
							margin-bottom: 15px;
						}

						.select {
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
</style>
